import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Image from '../../Atoms/Image/Image';
import Text from '../../Atoms/Text/Text';
import Video from '../../Atoms/Video/Video';
import ViewModal from '../ViewModal/ViewModal';
import { FileType, FileViewerProps } from './FileViewer.interfaces';
import {
  CarouselContainer,
  DivDownload,
  DivPDFThumb
} from './FileViewer.styles';
import Icon from '../../Atoms/Icon/Icon';
import { BiDownload } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';

const FileViewer = ({
  onClose,
  selectedFile,
  files,
  setSelectedFile
}: FileViewerProps) => {
  const { t: translate } = useTranslation();

  const imageTypes = ['JPG', 'PNG', 'JPEG'];
  const videoTypes = ['MP4', 'MOV', 'AVI', 'WMV', 'FLV', '3GP', 'MKV', 'WEBM'];
  const pdfTypes = ['PDF'];

  const renderFile = (file: FileType) => {
    const isImage = imageTypes.some(
      (imageType) =>
        file?.url?.toUpperCase().includes(imageType) ||
        file.name.toUpperCase().includes(imageType)
    );
    const isVideo = videoTypes.some(
      (videoType) =>
        file?.url?.toUpperCase().includes(videoType) ||
        file.name.toUpperCase().includes(videoType)
    );
    const isPDF = pdfTypes.some(
      (pdfType) =>
        file?.url?.toUpperCase().includes(pdfType) ||
        file.name.toUpperCase().includes(pdfType)
    );

    const handleDownload = () => {
      const link = document.createElement('a');
      link.href = file.url || URL.createObjectURL(file as unknown as Blob);
      link.download = file.name;
      link.click();
    };

    if (isImage) {
      return (
        <Image
          key={file.id}
          src={file.url || URL.createObjectURL(file as unknown as Blob)}
          alt={file.name}
          width="100%"
          height="100%"
          objectFit="contain"
        />
      );
    } else if (isVideo) {
      return (
        <Video
          key={file.id}
          src={file.url || URL.createObjectURL(file as unknown as Blob)}
          width="90%"
          height="100%"
          poster={file.thumb}
        />
      );
    } else if (isPDF) {
      return (
        <iframe
          key={file.id}
          title={file.name}
          src={file.url || URL.createObjectURL(file as unknown as Blob)}
          style={{
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            border: 'none',
            backgroundColor: 'transparent',
            display: 'block',
            margin: 'auto'
          }}
        />
      );
    } else {
      return (
        <DivDownload>
          <Text type="span">
            {translate('ThisFileCannotBeDisplayedClickBelowToDownloadTheFile')}
          </Text>
          <Icon Icon={BiDownload} onClick={handleDownload} />
        </DivDownload>
      );
    }
  };

  const renderThumbs = () => {
    return files.map((file) => {
      const isImage = imageTypes.some(
        (imageType) =>
          file?.url?.toUpperCase().includes(imageType) ||
          file.name.toUpperCase().includes(imageType)
      );
      const isVideo = videoTypes.some(
        (videoType) =>
          file?.url?.toUpperCase().includes(videoType) ||
          file.name.toUpperCase().includes(videoType)
      );

      if (isImage) {
        return (
          <Image
            key={file.id}
            src={file.url || URL.createObjectURL(file as unknown as Blob)}
            alt={file.name}
            width="100%"
            height="100%"
            objectFit="cover"
            maxHeight="100px"
          />
        );
      } else if (isVideo && file.thumb) {
        return (
          <Image
            key={file.id}
            src={file.thumb}
            alt={file.name}
            width="100%"
            height="100%"
            objectFit="cover"
            maxHeight="100px"
          />
        );
      } else {
        return (
          <DivPDFThumb key={file.id}>
            <Text type="span">{file.name}</Text>
          </DivPDFThumb>
        );
      }
    });
  };

  return (
    <ViewModal
      height={100}
      title={selectedFile.name}
      onClose={onClose}
      component={
        <CarouselContainer>
          <Carousel
            showStatus={false}
            showArrows={true}
            showThumbs={files.length > 1}
            thumbWidth={100}
            infiniteLoop={true}
            dynamicHeight={true}
            selectedItem={files.indexOf(selectedFile)}
            onChange={(index) => setSelectedFile(files[index])}
            renderThumbs={files.length > 1 ? renderThumbs : undefined}
          >
            {files.map((file) => renderFile(file))}
          </Carousel>
        </CarouselContainer>
      }
    />
  );
};

export default FileViewer;
